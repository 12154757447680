<template>
	<a-card class="guest-container">
		<template #title>
			<a-alert message="部门和组" type="info" show-icon closable v-show="appInfoAlert === 'open'" @close="closeAlert" style="margin-bottom: 10px">
				<template #icon><bulb-outlined style="margin-top: 20px" /></template>
				<template #description>
					<div class="alertContent">
						<!-- 管理员在当前页面对部门、业务组及其包含的账户、应用进行管理，也可以使用AD、LDAP 或 Excel文件的方式配置导入或同步。 -->
						<!-- <br /> -->
						在左侧的组织架构树中，可以右键点击某个部门对其进行操作，也可以左键选择某个部门，并在右侧为其进行创建账户、创建组、创建部门等操作。
					</div>
				</template>
			</a-alert>
		</template>

		<a-tabs size="small" v-model:activeKey="activeKey" @change="changeTab">
			<a-tab-pane v-for="(item, key) in activeKeyNameMapping" :key="key">
				<template #tab>
					<span>{{ item }}</span>
				</template>
			</a-tab-pane>
		</a-tabs>
		<div class="bottomDiv">
			<a-row style="width: 100%" :gutter="[16, 16]">
				<a-col :span="6" v-if="+activeKey <= 2">
					<a-card class="cardWidth">
						<template #title>组织架构</template>
						<div>
							<a-tree
								v-model:expandedKeys="expandedKeys"
								v-model:selectedKeys="selectedKeys"
								v-model:checkedKeys="checkedKeys"
								@select="changeSelect"
								checkable
								:height="600"
								:tree-data="treeList"
							>
								<template #title="{ name }">
									<span style="color: #1890ff">{{ name }}</span>
									<!-- <template>{{ title }}</template> -->
								</template>
							</a-tree>
						</div>
					</a-card>
				</a-col>
				<a-col :span="+activeKey <= 2 ? 18 : 24">
					<a-card class="cardWidth">
						<template #title>查看详情</template>
						<div>
							<div class="header">
								<a-button class="mr15" type="primary" @click="handleAdd" v-if="+activeKey <= 2"> 新增{{ activeKeyNameMapping[activeKey] }} </a-button>
								<a-button class="mr15" type="primary" @click="handleAdd" v-if="+activeKey === 3 && isGroup">
									新增{{ activeKeyNameMapping[activeKey] }}
								</a-button>
								<a-input style="width: 180px; margin-right: 15px" v-model:value="name" placeholder="请输入名称进行搜索" />
								<a-button type="primary" @click="getDataList">查询</a-button>
							</div>
							<a-table :loading="loading" :columns="columns" rowKey="id" :scroll="{ y: 500 }" :data-source="tableData" :pagination="false">
								<template #bodyCell="{ column, record }">
									<template v-if="column.key === 'status'">
										<span>
											<el-popconfirm
												confirm-button-text="确认"
												cancel-button-text="取消"
												title="确认修改应用状态吗"
												@confirm="confirmSwitch(record)"
												@cancel="cancelSwitch(record)"
											>
												<template #reference>
													<el-switch @click="clickSwitch(record)" v-model="record.status" />
												</template>
											</el-popconfirm>
										</span>
									</template>
									<template v-if="column.key === 'action'">
										<span>
											<a @click="handleEdit(record, 'edit')" style="margin-right: 20px"> 编辑 </a>
											<a @click="handleEdit(record, 'auth')" style="margin-right: 20px"> 授权 </a>
										</span>
									</template>
								</template>
							</a-table>
						</div>
					</a-card>
				</a-col>
			</a-row>
		</div>
	</a-card>
	<a-drawer width="840" placement="right" v-model:visible="dialogVisible" @close="changeDiagle" :closable="false">
		<p class="pStyleTitle">{{ isEdit ? `编辑${activeKeyNameMapping[activeKey]}` : `新增${activeKeyNameMapping[activeKey]}` }}</p>
		<a-tabs v-model:activeKey="activeKeyGroup" type="card" @change="changeUserTab">
			<a-tab-pane key="group" :tab="`${activeKeyNameMapping[activeKey]}信息`">
				<a-form :model="department" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off" @finish="onFinish">
					<a-row :gutter="16">
						<a-col :span="12" v-if="activeKey === '1'">
							<a-form-item label="父级" name="parentName" :rules="[{ required: true, message: 'Please input your parentName!' }]">
								<a-input :disabled="true" v-model:value="department.parentName" />
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入名称' }]">
								<a-input v-model:value="department.name" />
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="外部Id" name="externalId">
								<a-input v-model:value="department.externalId" />
							</a-form-item>
						</a-col>
						<a-col :span="12" v-if="activeKey === '1'">
							<a-form-item label="部门属性" name="departmentAttrCode" :rules="[{ required: true, message: '请选择部门属性' }]">
								<CommonQuerySelect
									api="getDepartmentListAttr"
									placeholder="部门属性"
									labelName="attrName"
									valueName="attrCode"
									v-model:code="department.departmentAttrCode"
									v-model:name="department.departmentAttrName"
								/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="描述" name="note">
								<a-input v-model:value="department.note" />
							</a-form-item>
						</a-col>
						<a-col :span="12" v-if="activeKey !== '2'">
							<a-form-item label="是否需要财务结算" name="needFinancialSettlement">
								<a-radio-group v-model:value="department.needFinancialSettlement" style="width: 100%">
									<a-radio :value="true">是</a-radio>
									<a-radio :value="false">否</a-radio>
								</a-radio-group>
							</a-form-item>
						</a-col>
						<template v-if="activeKey !== '2'">
							<a-col :span="12">
								<a-form-item label="公司全称" name="fullName">
									<a-input v-model:value="department.departmentExt.fullName" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="公司英文全称" name="fullNameEn">
									<a-input v-model:value="department.departmentExt.fullNameEn" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="法定代表人" name="corporation">
									<a-input v-model:value="department.departmentExt.corporation" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="记账本位币" :rules="[{ required: department.needFinancialSettlement, message: '请选择本位币' }]">
									<SelectCurrency v-model:code="department.departmentExt.currency" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="统一社会信用识别号" name="unifiedBusinessIdentifier">
									<a-input v-model:value="department.departmentExt.unifiedBusinessIdentifier" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="纳税人识别号" name="taxpayerIdentificationNo">
									<a-input v-model:value="department.departmentExt.taxpayerIdentificationNo" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="SWIFT编码" name="swiftCode">
									<a-input v-model:value="department.departmentExt.swiftCode" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="银行开户行名称" name="bankName">
									<a-input v-model:value="department.departmentExt.bankName" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="银行账号" :rules="[{ required: department.needFinancialSettlement, message: '请输入银行账号' }]">
									<a-input v-model:value="department.departmentExt.bankAccount" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="银行地址" name="bankAddress">
									<a-input v-model:value="department.departmentExt.bankAddress" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="银行持卡人" name="bankCardholder">
									<a-input v-model:value="department.departmentExt.bankCardholder" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="持卡人联系方式" name="cardholderMobile">
									<a-input v-model:value="department.departmentExt.cardholderMobile" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="国家/地区代码" name="countryCode">
									<a-input v-model:value="department.departmentExt.countryCode" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="公司地址" name="address">
									<a-input v-model:value="department.departmentExt.address" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="联系电话" name="mobile">
									<a-input v-model:value="department.departmentExt.mobile" />
								</a-form-item>
							</a-col>
							<a-col :span="12">
								<a-form-item label="成立日期" name="establishTime">
									<DateRangePicker
										:needhms="false"
										:singleDatePicker="true"
										:startTime="department.departmentExt.establishTime"
										@get_date="getDate"
									/>
								</a-form-item>
							</a-col>
						</template>
						<a-col :span="24">
							<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
								<a-button type="primary" html-type="submit" :loading="submitLoading">Submit</a-button>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-tab-pane>
			<a-tab-pane v-if="isEdit" key="member" :tab="`${activeKeyNameMapping[activeKey]}成员`" force-render>
				<a-row :gutter="16" style="margin-bottom: 15px">
					<a-col>
						<a-input style="width: 180px" v-model:value="userParams.userName" placeholder="请输入名称进行搜索" />
					</a-col>
					<a-col>
						<a-button type="primary" @click="getUserList('departmentId')"> 查询 </a-button>
					</a-col>
					<a-col><a-button @click="showUserList">添加成员</a-button></a-col>
					<a-col>
						<a-button :disabled="user.selectedUser.length < 1" @click="delUserList()"> 删除成员 </a-button>
					</a-col>
				</a-row>
				<div style="height: 600px; overflow-y: scroll">
					<a-table
						:row-selection="{
							selectedRowKeys: user.selectedUser,
							onChange: onSelectUser,
							type: `checkbox`,
						}"
						:loading="userLoading"
						:columns="userColumns"
						:data-source="user.tableData"
						:pagination="false"
					>
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'status'">
								<span>
									{{ record.status === 1 ? "正常" : "禁用" }}
								</span>
							</template>
							<template v-if="column.key === 'aciton'">
								<span>
									<a @click="delUserList(record)" style="margin-right: 20px"> 删除 </a>
								</span>
							</template>
						</template>
					</a-table>
				</div>

				<a-pagination
					style="float: right; margin-top: 10px"
					v-model:current="userParams.page_no"
					v-model:pageSize="userParams.page_size"
					show-size-changer
					:total="userParams.total"
					:show-total="(total) => `总计 ${total} 条`"
					@change="changePageUser"
				>
					<template v-slot:buildOptionText="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
				<a-drawer v-model:visible="dialogVisibleChildren" title="全部成员" width="620">
					<a-row :gutter="16" style="margin-bottom: 15px">
						<a-col>
							<a-input style="width: 180px" v-model:value="allUserParams.userName" placeholder="请输入名称进行搜索" />
						</a-col>
						<a-col>
							<a-button type="primary" @click="getUserList('all')"> 查询原成员 </a-button>
						</a-col>
						<a-col>
							<a-button type="primary" :disabled="user.selectedOriginUser.length < 1" @click="addUser"> 确认添加 </a-button>
						</a-col>
					</a-row>
					<div style="height: 600px; overflow-y: scroll">
						<a-table
							:row-selection="{
								selectedRowKeys: user.selectedOriginUser,
								onChange: onSelectOriginUser,
								type: `checkbox`,
							}"
							:columns="userOriginColumns"
							:data-source="user.allUserData"
							:loading="allUserLoading"
							:pagination="false"
						>
							<template #bodyCell="{ column, record }">
								<template v-if="column.key === 'status'">
									<span>
										{{ record.status === 1 ? "正常" : "禁用" }}
									</span>
								</template>
							</template>
						</a-table>
					</div>

					<a-pagination
						style="float: right; margin-top: 10px"
						v-model:current="allUserParams.page_no"
						v-model:pageSize="allUserParams.page_size"
						show-size-changer
						:total="allUserParams.total"
						:show-total="(total) => `总计 ${total} 条`"
						@change="changePageAllUser"
					>
						<template v-slot:buildOptionText="props">
							<span>{{ props.value }}条/页</span>
						</template>
					</a-pagination>
				</a-drawer>
			</a-tab-pane>
		</a-tabs>
	</a-drawer>
</template>

<script>
import { computed, nextTick, onMounted, reactive, toRefs } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { localGet, localSet } from "@/common/utils"
import { message } from "ant-design-vue"
import DateRangePicker from "@/components/DateRangePicker"
import SelectCurrency from "@/components/SelectCurrency"
import CommonQuerySelect from "@/components/CommonQuerySelect"
export default {
	name: "Organization",
	components: {
		DateRangePicker,
		SelectCurrency,
		CommonQuerySelect,
	},
	setup() {
		const store = useStore()
		const state = reactive({
			loading: false,
			userLoading: false,
			submitLoading: false,
			allUserLoading: false,
			merchantAppId: "",
			tableData: [], // 列表数据
			columns: [
				{
					title: "部门名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "描述信息",
					dataIndex: "note",
					key: "note",
				},
				{
					title: "状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "操作",
					key: "action",
				},
			],
			activeKey: "1",
			nowPartyId: "",
			activeKeyGroup: "group",
			isEdit: false,
		})
		onMounted(() => {
			getTreeList(1)
		})
		const params = reactive({
			level: "",
			name: "", // 名称
			parentId: 0, // 父级ID
			status: true, // 状态1 可用 0禁用
			tree: true, //存储请求时的pagesize
			page_no: 1,
			page_size: 100,
		})
		// 获取列表
		const getDataList = (propsParams = {}) => {
			state.loading = true
			params.status = ""
			let parentId
			switch (+state.activeKey) {
				case 1:
				case 2:
					parentId = state.nowPartyId ? state.nowPartyId : treeData.treeList[0]?.id
					break
				case 3:
					if (isGroup.value) {
						parentId = treeData.treeList[0]?.id
					} else {
						parentId = undefined
					}
					break
				case 4:
					parentId = undefined
					break
				default:
					parentId = undefined
			}
			const data = {
				...params,
				parentId,
				merchantId: store.getters["user/merchantId"],
				...propsParams,
			}
			delete data.total
			axios
				.post(api.departmentList, data)
				.then((res) => {
					addKey(res)
					let resData = JSON.parse(JSON.stringify(res))
					changeChildren(resData)
					state.tableData = resData
					state.loading = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}

		const addKey = (value) => {
			value.forEach((item) => {
				item.key = item.id + "-" + item.name + "-" + item.externalId
				treeData.expandedKeys.push(item.key)
				if (item.children && item.children.length > 0) {
					addKey(item.children)
				}
			})
		}
		const changeChildren = (value) => {
			value.forEach((item) => {
				item.key = item.id + "-" + item.name + "-" + item.externalId
				treeData.expandedKeys.push(item.key)
				delete item.children
			})
		}
		const changeTab = (value) => {
			params.type = Number(value)
			state.activeKeyGroup = "group"
			state.tableData = []
			nextTick(() => {
				if (+value <= 2) {
					getTreeList(+value)
				} else {
					getDataList()
				}
			})
		}
		// 新增
		const handleAdd = () => {
			if (+state.activeKey <= 2 && treeData.selectedKeys.length === 0) {
				message.error("请点击左侧组织架构，选择父级")
				return
			}
			state.isEdit = false
			diagleDate.dialogVisible = true
			let keys = +state.activeKey <= 2 ? treeData.selectedKeys[0].split("-") : treeData.treeList[0].key.split("-")
			departmentData.department.parentExternalId = keys[2]
			departmentData.department.parentName = keys[1]
			departmentData.department.parentId = keys[0]
			departmentData.department.name = ""
			departmentData.department.note = ""
			departmentData.department.externalId = ""
			departmentData.department.type = Number(state.activeKey)
			departmentData.department.id = ""
			departmentData.department.departmentExt = { status: true, currency: undefined }
		}
		// 编辑
		const handleEdit = (id, type) => {
			state.isEdit = true
			let data = {
				id: id.id,
				needParent: true,
			}
			axios.post(api.departmentInfo, data).then((res) => {
				departmentData.department.parentName = res.parent?.name
				departmentData.department.parentId = res.parent?.id
				departmentData.department.name = res.name
				departmentData.department.departmentAttrCode = res.departmentAttrCode
				departmentData.department.departmentAttrName = res.departmentAttrName
				departmentData.department.note = res.note
				departmentData.department.externalId = res.externalId
				departmentData.department.id = res.id
				departmentData.department.needFinancialSettlement = res.needFinancialSettlement
				departmentData.department.departmentExt = res.departmentExt || {}
				departmentData.department.departmentExt.departmentId = res.id
				diagleDate.dialogVisible = true
				if (type == "edit") {
					state.activeKeyGroup = "group"
					user.user.selectedUser = []
					user.user.selectedOriginUser = []
				} else {
					state.activeKeyGroup = "member"
					getUserList("departmentId")
					user.user.selectedUser = []
					user.user.selectedOriginUser = []
				}
			})
		}

		// 详情模板 控制页面显示隐藏
		const diagleDate = reactive({
			dialogVisible: false,
			dialogVisibleChildren: false, //业务组成员
			appInfo: {},
			appInfoAlert: localGet("appInfoAlert") || "open",
		})
		//关闭 提示信息弹框
		const closeAlert = () => {
			diagleDate.appInfoAlert = "close"
			localSet("appInfoAlert", "close")
		}
		const changeDiagle = (type) => {
			if (type === "info" || type === "ouathInfo") {
				// console.log(type, value)
			} else {
				diagleDate.dialogVisible = false
				user.user.tableData = []
				user.userParams.total = 0
				// user.allUserParams.total = 0;
				// user.user.allUserData = [];
			}
		}
		const treeData = reactive({
			treeList: [],
			expandedKeys: [],
			selectedKeys: [],
			checkedKeys: [],
			treeParams: {
				level: "",
				name: "", // 名称
				parentId: 0, // 父级ID
				status: true, // 状态1 可用 0禁用
				tree: true, //存储请求时的pagesize
			},
			// params.tree=false
		})
		// 获取列表
		const getTreeList = (type) => {
			treeData.treeParams.status = ""
			const data = {
				...treeData.treeParams,
				merchantId: store.getters["user/merchantId"],
				typeList: [type, 3, 4],
			}
			delete data.total
			axios
				.post(api.departmentList, data)
				.then((res) => {
					addKey(res)
					let data = JSON.parse(JSON.stringify(res))
					changeChildren(data)
					treeData.treeList = res
					getDataList({ type })
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		const changeSelect = (value) => {
			let selectedKey = value[0].split("-")
			// 点击树的时候需要存下来当前点击的partyId
			state.nowPartyId = selectedKey[0]
			nextTick(() => {
				getDataList()
			})
		}
		const departmentData = reactive({
			department: {
				id: "",
				leaf: true,
				level: "",
				merchantId: store.getters["user/merchantId"],
				name: "",
				note: "",
				parentId: 0,
				status: true,
				externalId: "", //外部Id
				parentExternalId: "", //父级外部Id
				departmentExt: {},
			},
			departmentParamas: {
				userName: "",
				merchantId: store.getters["user/merchantId"],
				total: 0, // 总条数
				page_no: 1, // 当前页
				page_size: 10, // 分页大小
				old_page_size: "",
			},
		})
		// 添加部门
		const onFinish = () => {
			// console.log(state.activeKey)
			let paramsData = departmentData.department
			paramsData.type = Number(state.activeKey)

			if (departmentData.department.needFinancialSettlement) {
				if (!departmentData.department.departmentExt.currency) {
					return message.warning("请选择结算币种")
				}
				if (!departmentData.department.departmentExt.bankAccount) {
					return message.warning("请输入银行账号")
				}
			}

			state.submitLoading = true
			axios
				.post(api.departmentSave, {
					department: paramsData,
				})
				.then(() => {
					message.success("操作成功")
					//添加完成之后调两次接口，一次是当前列表，二是左边的树结构
					params.type = Number(state.activeKey)

					getTreeList(Number(state.activeKey))

					diagleDate.dialogVisible = false
					state.submitLoading = false
				})
				.catch((err) => {
					console.log(err)
					state.submitLoading = false
				})
		}
		const user = reactive({
			userParams: {
				userName: "",
				merchantId: store.getters["user/merchantId"],
				total: 0, // 总条数
				page_no: 1, // 当前页
				page_size: 10, // 分页大小
				old_page_size: "",
			},
			allUserParams: {
				userName: "",
				merchantId: store.getters["user/merchantId"],
				total: 0, // 总条数
				page_no: 1, // 当前页
				page_size: 10, // 分页大小
				old_page_size: "",
			},
			user: {
				tableData: [], //部门用户列表
				allUserData: [], //全部用户列表
				selectedUser: [], //部门成员的勾选集合
				selectedOriginUser: [], //全部成员的选择集合
			},
			userColumns: [
				{
					title: "用户名",
					dataIndex: "userName",
					key: "name",
				},
				{
					title: "手机号",
					dataIndex: "mobile",
					key: "mobile",
				},
				{
					title: "状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "操作",
					key: "aciton",
				},
			],
			userOriginColumns: [
				{
					title: "用户名",
					dataIndex: "userName",
					key: "name",
				},
				{
					title: "手机号",
					dataIndex: "mobile",
					key: "mobile",
				},
				{
					title: "状态",
					dataIndex: "status",
					key: "status",
				},
			],
		})
		const getUserList = (type) => {
			let data = ""
			if (type === "departmentId") {
				data = {
					...user.userParams,
				}
				data.departmentId = departmentData.department.id
				state.userLoading = true
			} else {
				data = {
					...user.allUserParams,
				}
				data.excludeDepartmentIds = [departmentData.department.id]
				state.allUserLoading = true
			}

			axios
				.post(api.getUserList, data)
				.then((res) => {
					res.list.forEach((item) => {
						item.key = item.userName
					})
					if (type === "departmentId") {
						user.user.tableData = res.list
						user.userParams.total = res.total
					} else {
						user.user.allUserData = res.list
						user.allUserParams.total = res.total
					}
					state.userLoading = false
					state.allUserLoading = false
				})
				.catch((err) => {
					state.userLoading = false
					console.log(err)
					state.loading = false
					state.allUserLoading = false
				})
		}
		// 当前页 及当前页
		const changePageAllUser = (page, pageSize) => {
			// console.log(page, pageSize)
			user.allUserParams.page_no = page
			user.allUserParams.page_size = pageSize
			getUserList("all")
		}
		// 当前页 及当前页
		const changePageUser = (page, pageSize) => {
			user.userParams.page_no = page
			user.userParams.page_size = pageSize
			getUserList("departmentId")
		}
		const showUserList = () => {
			diagleDate.dialogVisibleChildren = true
			getUserList("all")
		}
		const onSelectOriginUser = (value) => {
			user.user.selectedOriginUser = value
		}
		const onSelectUser = (value) => {
			user.user.selectedUser = value
		}
		const changeUserTab = (value) => {
			if (value === "member") {
				getUserList("departmentId")
			}
		}
		const addUser = () => {
			let data = {
				merchantId: store.getters["user/merchantId"],
				departmentId: departmentData.department.id,
				departmentType: +state.activeKey,
				userIdList: [],
			}
			user.user.allUserData.forEach((item) => {
				user.user.selectedOriginUser.forEach((userName) => {
					if (item.userName == userName) {
						data.userIdList.push(item.id)
					}
				})
			})
			// console.log(data)
			axios
				.post(api.departmentAddUsers, data)
				.then(() => {
					message.success("添加成功")
					diagleDate.dialogVisibleChildren = false
					getUserList("departmentId")
				})
				.catch((err) => {
					console.log(err)
				})
		}
		const delUserList = (value) => {
			let data = {
				merchantId: store.getters["user/merchantId"],
				departmentId: departmentData.department.id,
				departmentType: +state.activeKey,
				userIdList: [],
			}
			if (value) {
				data.userIdList = [value.id]
			} else {
				user.user.tableData.forEach((item) => {
					user.user.selectedUser.forEach((userName) => {
						if (item.userName == userName) {
							data.userIdList.push(item.id)
						}
					})
				})
			}
			axios
				.post(api.departmentDelUsers, data)
				.then(() => {
					message.success("删除成功")
					diagleDate.dialogVisibleChildren = false
					getUserList("departmentId")
				})
				.catch((err) => {
					console.log(err)
				})
		}
		const confirmSwitch = (value) => {
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
			let department = {
				id: value.id,
				leaf: true,
				level: value.level,
				merchantId: store.getters["user/merchantId"],
				name: value.name,
				note: value.note,
				parentId: value.parentId,
				status: value.status,
				externalId: value.externalId, //外部Id
				parentExternalId: value.parentExternalId, //父级外部Id
				type: value.type,
			}
			departmentData.department = department
			onFinish()
		}
		const cancelSwitch = () => {}
		const clickSwitch = (value) => {
			// console.log(value)
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
		}

		const getDate = (data) => {
			departmentData.department.departmentExt.establishTime = data
		}

		const activeKeyNameMapping = computed(() => ({
			1: "部门",
			2: "业务组",
			3: "公司",
			4: "集团",
		}))
		const isGroup = computed(() => store.getters["user/merchantType"] === "GROUP")
		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			...toRefs(user),
			...toRefs(treeData),
			...toRefs(departmentData),
			changeSelect,
			showUserList,
			getUserList,
			addUser,
			delUserList,
			changeUserTab,
			getDataList,
			getTreeList,
			handleAdd,
			handleEdit,
			changePageUser,
			changePageAllUser,
			onSelectOriginUser,
			onSelectUser,
			closeAlert,
			changeDiagle,
			onFinish,
			changeTab,
			confirmSwitch,
			cancelSwitch,
			clickSwitch,
			activeKeyNameMapping,
			isGroup,
			getDate,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding-bottom: 10px;
}
.a-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.cardBox {
	display: flex;
	justify-content: space-around;
	.ant-card-head-title {
		font-size: 12px;
	}
}
:deep .ant-card {
	width: 25% !important;
	height: 145px;
	font-size: 12px;
	margin: 0 10px;
	.ant-card-head {
		font-size: 12px;
		padding: 0 16px;
	}
	.ant-card-body {
		padding-bottom: 0;
		padding: 16px;
		.ant-btn {
			font-size: 12px;
		}
		.cardP {
			margin-bottom: 0;
			a {
				margin-right: 18px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.cardContent {
			color: #909399;
			height: 40px;
		}
	}
}
.alertContent {
	font-size: 12px;
}

.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
.secretStyle {
	font-weight: 900;
	margin-right: 20px;
}
.copy:hover {
	cursor: pointer;
}
.bottomDiv {
	display: flex;
	width: 100%;
	height: calc(100vh - 350px);
	justify-content: space-between;
	.cardWidth {
		width: 100% !important;
		height: 100% !important;
		margin: 0 5px;
	}
}
:deep .ant-tabs-tab-btn {
	padding: 0 20px;
}
.header {
	display: flex;
	margin-bottom: 15px;
}
</style>
