<template>
	<a-select
		allowClear
		:mode="mode"
		show-search
		:maxTagCount="4"
		style="width: 100%"
		:placeholder="_selfPlaceholder"
		:disabled="_selfDisabled"
		:default-active-first-option="false"
		v-model:value="selectedValue"
		:filter-option="filterOption"
		:not-found-content="loading ? undefined : null"
		@focus="getList"
		@change="onChange"
	>
		<template #notFoundContent>
			<a-spin v-if="loading" size="small" />
		</template>
		<a-select-option v-for="(item, index) in option" :value="String(item[valueName])" :key="index">
			{{ item[labelName] }}
		</a-select-option>
	</a-select>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps, defineEmits } from "vue"
import { useStore } from "vuex"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"

const props = defineProps({
	placeholder: {},
	disabled: {},
	code: {},
	name: {},
	mode: {
		type: String,
		default: "",
	},
	api: {
		type: String,
	},
	params: {
		type: Object,
		default: () => ({}),
	},
	init: {
		type: Boolean,
		default: false,
	},
	valueName: {
		type: String,
		default: "code",
	},
	labelName: {
		type: String,
		default: "name",
	},
})

const emit = defineEmits(["update:code", "update:name", "change"])
const store = useStore()

const selectedValue = ref(undefined)
const option = ref([])
const loading = ref(false)

const _selfPlaceholder = computed(() => props.placeholder)
const _selfDisabled = computed(() => props.disabled || false)

onMounted(() => {
	if (props.init) {
		getList()
	}
})

watch(
	() => props.code,
	() => {
		if (option.value.length > 0) {
			setDefault()
		} else if (!props.name) {
			getList()
		}
	}
)

watch(
	() => props.name,
	(val) => {
		if (val) {
			selectedValue.value = val
		}
	},
	{ immediate: true }
)

const getList = async () => {
	if (option.value.length > 0) return
	loading.value = true
	try {
		let res = await axios.post(api[props.api], {
			merchantId: store.getters["user/merchantId"],
			...props.params,
		})
		let list = res.list || res || []
		if (list.length === 0) {
			message.warning("数据为空")
		}
		option.value = list
		setDefault()
	} finally {
		loading.value = false
	}
}

const setDefault = () => {
	if (props.name) return
	selectedValue.value = undefined
	if (props.code != undefined) {
		onChange(props.code)
	} else if (option.value.length === 1) {
		onChange(option.value[0][props.valueName])
	}
}

const onChange = (value) => {
	if (value !== undefined) {
		value = String(value)
	}
	selectedValue.value = value
	emit("update:code", value)
	let item = option.value.find((elem) => String(elem[props.valueName]) === value) || {}
	emit("update:name", item[props.labelName])
	emit("change", item)
}

const filterOption = (input, option) => {
	return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
</script>
